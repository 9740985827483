import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Privacy from "./privacy";
import Calendar from "./calendar";
import "./index.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Gallery from "./gallery";

const router = createBrowserRouter([
  {
    path: "/privacy",
    element: <Privacy />
  },
  {
    path: "/calendar",
    element: <Calendar />
  },
  {
    path: "/gallery",
    element: <Gallery />
  },
  {
    path: "/",
    element: <App />
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}>
    </RouterProvider>
  </React.StrictMode>
);
