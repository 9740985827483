import { useState, Fragment, useEffect } from 'react'
import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { FacebookProvider } from 'react-facebook';
import { HashLink } from 'react-router-hash-link';
import Header from './components/header';
import Footer from './components/footer';

function Calendar() {

  const [days, setDays] = useState([
    { date: '2024-09-30' },
    { date: '2024-09-31' },
    { date: '2024-10-01', isCurrentMonth: true },
    { date: '2024-10-02', isCurrentMonth: true },
    { date: '2024-10-03', isCurrentMonth: true },
    { date: '2024-10-04', isCurrentMonth: true },
    { date: '2024-10-05', isCurrentMonth: true },
    { date: '2024-10-06', isCurrentMonth: true },
    { date: '2024-10-07', isCurrentMonth: true },
    { date: '2024-10-08', isCurrentMonth: true },
    { date: '2024-10-09', isCurrentMonth: true },
    { date: '2024-10-10', isCurrentMonth: true },
    { date: '2024-10-11', isCurrentMonth: true },
    { date: '2024-10-12', isCurrentMonth: true },
    { date: '2024-10-13', isCurrentMonth: true },
    { date: '2024-10-14', isCurrentMonth: true },
    { date: '2024-10-15', isCurrentMonth: true },
    { date: '2024-10-16', isCurrentMonth: true },
    { date: '2024-10-17', isCurrentMonth: true },
    { date: '2024-10-18', isCurrentMonth: true },
    { date: '2024-10-19', isCurrentMonth: true },
    { date: '2024-10-20', isCurrentMonth: true },
    { date: '2024-10-21', isCurrentMonth: true },
    { date: '2024-10-22', isCurrentMonth: true },
    { date: '2024-10-23', isCurrentMonth: true },
    { date: '2024-10-24', isCurrentMonth: true },
    { date: '2024-10-25', isCurrentMonth: true },
    { date: '2024-10-26', isCurrentMonth: true },
    { date: '2024-10-27', isCurrentMonth: true },
    { date: '2024-10-28', isCurrentMonth: true },
    { date: '2024-10-29', isCurrentMonth: true },
    { date: '2024-10-30', isCurrentMonth: true },
    { date: '2024-10-31', isCurrentMonth: true },
    { date: '2024-11-01' },
    { date: '2024-11-02' },
  ]);

  function getNumEventsForDay(day) {
    let count = 0;
    if (day.indexOf('2024-10') === -1) {
      return 0;
    }
    day = day.replace('2024-10', '').replace('-0', '').replace('-', '');
    for (let i = 0; i < faq.length; i++) {
      let s = faq[i].date.replaceAll(',', '').split(' ');

      if (s.indexOf(day) !== -1) {
        count++;
      }
    }
    return count;
  }

  const [selectedDay, setSelectedDay] = useState(undefined);

  function showEvent(event) {
    if (!selectedDay) {
      return true;
    }
    let s = event.date.replaceAll(',', '').split(' ');
    if (s.indexOf(selectedDay) !== -1) {
      return true;
    }
    return false;
  }

  const [faq, setFAQ] = useState([
    {
      question: "Hop Garden Haunted Basement",
      img: "/images/hopgarden.png",
      answer: "Head to The Hop Garden Fridays and Saturdays for a bite and brew, and venture down into the Haunted Basement if you dare. This experience is exclusive to Hop Garden patrons and not open to the general public. We kindly request a $1-5 donation, all of which will benefit our friends at Aware In Evansville.",
      date: "Oct 11, 12, 18, 19, 25, 26, 31",
      time: "5-8pm",
      link: "https://www.facebook.com/HopGardenEvansville",
      open: false
    }, {
      question: "Sunflower Picking",
      img: "/images/pleasantprarie.jpg",
      answer: "Our 104 and Evansville locations are hosting sunflower seedhead picking!  Bring your own clippers and any size bag for $5/bag. You just have to be able to carry it to your car! This event will continue for the remainder of the month during fall hours only.  For those of you that don’t want to pick, pre-picked bags are available for $10.",
      date: "Saturdays & Sundays",
      time: "10am-4pm",
      link: "https://www.facebook.com/PleasantPrairieGreenhouseEvansville",
      open: false
    }, {
      question: "Trunk or Treat @ Infinity Martial Arts",
      img: "/images/infinity.png",
      answer: "When we were kids, Halloween was one of our most favorite holidays! And we’re pretty sure it’s your kids\' too. They get hyped and happy when they hear a Halloween party is coming…and it\'s located right in the neighborhood!",
      date: "Oct 19",
      time: "1:30-3:30pm",
      link: "https://www.facebook.com/events/879054900268277/",
      open: false
    }, {
      question: "Underground Music Halloween Bash",
      img: "/images/eum.png",
      answer: "This is our final OUTDOOR show of the 2024 season and we are bringing back our (touring) Kansas friends to celebrate!!! Yes that's right, we are returning to 344 Garfield St. in our lovely city of Evansville, WI. for this HALLOWEEN show AND you guessed it, there will be dancing, heavy swaying AND a best costume award.",
      date: "Oct 19",
      time: "5pm",
      link: "https://www.facebook.com/events/853948469877620/",
      open: false
    },
    {
      question: "ECT Science Fiction Radio Show",
      img: "/images/ect.jpg",
      answer: "This year we are aiming our focus on science fiction. We will present two stories by Ray Bradbury – There Will Come Soft Rains and Zero Hour. The evening will conclude with a telling of War Of The Worlds. No radio production would be complete without our audience favorite custom jingles scattered throughout the show. Mark your calendars and join us for an evening of fun!",
      date: "Oct 24, 25, 26",
      time: "7pm & 3pm shows available",
      link: "https://ecttickets.ludus.com/index.php?show_id=200465004",
      open: false
    },
    {
      question: "All Paws Annual Fall Pawty",
      img: "/images/allpaws.jpeg",
      answer: "Every dog that comes in with a costume (or bring a picture of them if they aren’t a social dog) will receive a goodie bag. The costume contest winner will receive an additional basket filled with goodies! We will also have: pup cup bar, fall themed dog games, photo op, store sales, daycare room tour, human snacks, adoptable cats and dogs, and trick or treating!",
      date: "Oct 26",
      time: "9am-5pm",
      link: "https://www.facebook.com/events/510650564933608/",
      open: false
    },
    {
      question: "Merchant Trick or Treat",
      img: "/images/ecc.png",
      answer: "Merchant Trick or Treat & Scavenger Hunt! Dress in your costume and visit businesses for a trick or treat! Scavenger hunt to add to the fun!",
      date: "Oct 26",
      time: "11am-2pm",
      link: "https://www.facebook.com/events/1167569941215518/",
      open: false
    }, {
      question: "Jack-o-Lantern Jamboree",
      img: "/images/evansvilleschools.png",
      answer: "Games, food, raffle and more, costumes encouraged! Cash only accepted.",
      date: "Oct 26",
      time: "5-7pm",
      link: "https://www.facebook.com/EvansvilleK8PTO",
      open: false
    }, {
      question: "Forsaken Souls Free Haunted House",
      img: "/images/forsakensouls-shirt.png",
      answer: "Forsaken Souls is a small non-commercial home haunt in Evansville, WI. Join us this Halloween for a night full of frights!",
      date: "Oct 27, 31",
      time: "Kid Friendly & Full Scare Hours",
      link: "https://www.forsakensouls.com/",
      open: false
    }, {
      question: "Witches Brew Ghoul's Day Out",
      img: "/images/lovegoods.png",
      answer: "Join us for a Sunday Fun-Day Spooktacular Event!! Each ticket includes a cauldron cup and potion bottles to craft the perfect cocktail, delicious pizza to snack on, supplies to craft a witch broom, and optional tarot readings. Our coffee & cocktail menu will be available to purchase during the event. Purchase a ticket to reserve your spot! Limited seating is available.",
      date: "Oct 27",
      time: "1-4pm",
      link: "https://www.facebook.com/events/517696760895610/",
      open: false
    }, {
      question: "Trapper's Halloween Bingo",
      img: "/images/trappers.png",
      answer: "Costume night. Trappers giving out $50, $30 & $20 gift certificates for prizes. Come join us for some bingo fun. BBB has 100% payouts. Bring your daubers if you have them. Please remember your DOLLAR BILLS. Come early and enjoy our daily specials.",
      date: "Oct 30",
      time: "6-8pm",
      link: "https://www.facebook.com/events/trappers-bar-and-grill/trappers-halloween-bingo/453509824377289/",
      open: false
    }, {
      question: "Evansville Trick or Treat",
      img: "/images/CoE.png",
      answer: "Put on your costumes and enjoy the night with your neighbors!",
      date: "Oct 31",
      time: "5-7pm",
      link: "",
      open: false
    }, {
      question: "Night Owl Costume Trivia Party",
      img: "/images/nightowl.png",
      answer: "Prizes will be awared for the best team costumes, best individual costumes, and a spooooky big grand prize for trivia.",
      date: "Oct 31",
      time: "7pm",
      link: "https://www.facebook.com/nightowlsportspubandeatery",
      open: false
    }
  ]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  let [scrolled, setScrolled] = useState(false);
  let [scrolledLarge, setScrolledLarge] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', (event) => {
      let scrollTop = event.target.scrollingElement.scrollTop;
      let elementPosition = document.getElementById('logos').offsetTop;

      if (scrollTop > document.getElementById('logos').offsetTop + 100) {
        setScrolledLarge(true);
      } else {
        setScrolledLarge(false);
      }

      if (scrollTop > elementPosition) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }

    });
    // set isToday on the correct day
    setDays(
      days.map((d) => {
        const today = new Date();
        if (d.date === `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`) {
          return { ...d, isToday: true };
        } else {
          return { ...d };
        }
      })
    );
  }, []);

  return (
    <>
      <main id="main"><FacebookProvider appId="360440440445559">

        <div className="bg-slate-900">

          <Header scrolled={scrolled} scrolledLarge={scrolledLarge} />

          <div id="home" className="relative isolate">

            <div
              aria-hidden="true"
              className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-2xl sm:-top-80"
            >

              <div
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              />

            </div>
            <div className="pt-16 sm:pt-16 lg:pt-32 pb-12 relative overflow-hidden">
              {/* <div className="pt-16 sm:pt-16 lg:pt-32 pb-24 relative overflow-hidden border-b-2 border-lime-500 shadow-2xl"> */}
              <img
                alt=""
                src="/images/bar.jpg"
                className="absolute inset-0 -z-10 h-full w-full object-cover blur-sm contrast-125 opacity-50 overflow-hidden"
              />
              <div className="mx-auto max-w-2xl">
                <div className="text-center" id="logos">
                  <HashLink to="/"><img src="/images/forsakensouls-transparent.png" alt="Forsaken Souls Haunted Attraction" /></HashLink>

                  <h1 className="text-3xl text-slate-50 sm:text-4xl ghoulish pt-6 sm:pt-0 drop-shadow">
                    Evansville 2024<span className="text-lime-500 mt-4 block">Halloween Calendar</span>
                  </h1>

                  {/* <center><img src="/images/spookeasy-shadow.png" alt="Spookeasy Haunted House" className="mt-12 max-w-xs sm:max-w-lg" /></center> */}
                  <p id="description" className="mt-12 text-lg leading-8 text-slate-200 px-6 drop-shadow">
                    Enjoy all the Halloween events around Evansville, WI!<br /><HashLink to="/#contact" className="text-indigo-400 hover:text-indigo-300">Contact Us</HashLink> to add your event.
                  </p>
                  {/* <HashLink to="#location" className="animate-bounce block">
                <ChevronDoubleDownIcon aria-hidden="true" className="h-6 w-6 text-lime-500 mx-auto mt-12 -mb-12" />
              </HashLink> */}
                  {/* <div className="mt-10 flex items-center justify-center gap-x-6">
                <HashLink
                  to="#"
                  className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-slate-50 shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                >
                  Get started
                </HashLink>
                <HashLink to="#" className="text-sm font-semibold leading-6 text-slate-50">
                  Learn more <span aria-hidden="true">→</span>
                </HashLink>
              </div> */}
                </div>

              </div></div>

            <div id="faq" className="max-w-5xl mx-auto">
              <div className="mx-auto max-w-5xl px-6">
                <div className="text-center text-slate-300 pb-8">

                  <div className="max-w-sm mx-auto">
                    <div className="text-center">
                      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-slate-300">

                        <div>Sun</div>
                        <div>M</div>
                        <div>T</div>
                        <div>W</div>
                        <div>T</div>
                        <div>F</div>
                        <div>Sat</div>
                      </div>
                      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg text-sm shadow ring-1 ring-slate-600/50">
                        {days.map((day, dayIdx) => (
                          <button
                            key={day.date}
                            onClick={() => {
                              if (!day.isCurrentMonth) {
                                return;
                              }
                              if (day.isSelected) {
                                setSelectedDay(undefined);
                                setDays(
                                  days.map((d) => {
                                    return { ...d, isSelected: false };
                                  })
                                );
                                return;
                              }
                              setSelectedDay(day.date.split('-').pop().replace(/^0/, ''));
                              // Set the correct day to selected:
                              setDays(
                                days.map((d) => {
                                  if (d.date === day.date) {
                                    return { ...d, isSelected: true };
                                  } else {
                                    return { ...d, isSelected: false };
                                  }
                                })
                              );
                            }}
                            type="button"
                            className={classNames(
                              'py-1.5 focus:z-10',
                              day.isCurrentMonth ? 'bg-slate-600/50 hover:bg-teal-500 group' : 'bg-slate-600/25',
                              (day.isSelected || day.isToday) && 'font-semibold',
                              day.isSelected && 'text-white',
                              !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-slate-50',
                              !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-slate-400',
                              day.isToday && !day.isSelected && 'text-teal-500 font-bold',
                              dayIdx === 0 && 'rounded-tl-lg',
                              dayIdx === 6 && 'rounded-tr-lg',
                              dayIdx === days.length - 7 && 'rounded-bl-lg',
                              dayIdx === days.length - 1 && 'rounded-br-lg',
                              day.isSelected && day.isToday && 'bg-teal-500 text-slate-50',
                              day.isSelected && !day.isToday && 'bg-teal-500 text-slate-50',
                            )}
                          >
                            <div className="flex flex-col group-hover:text-slate-50"><time
                              dateTime={day.date}
                              className={classNames(
                                'mx-auto flex h-7 w-7 items-center justify-center rounded-full',

                              )}
                            >
                              {day.date.split('-').pop().replace(/^0/, '')}

                            </time>
                              <div className="-mt-2 text-lime-500 h-4 pt-0.5">
                                {Array.from({ length: getNumEventsForDay(day.date) }, (_, index) => (
                                  <span className={classNames(day.isSelected ? "text-slate-50" : "", "group-hover:text-slate-50")}>&#x2022;</span>
                                ))}
                              </div></div>
                          </button>
                        ))}
                      </div>
                    </div>

                  </div></div>
                <div>
                  <h2 className="text-2xl text-slate-50 mb-6">
                    {selectedDay ? `Events on October ${selectedDay}` : 'All Events'}
                  </h2>
                  <button onClick={() => {
                    setSelectedDay(undefined);
                    setDays(
                      days.map((d) => {
                        return { ...d, isSelected: false };
                      })
                    );
                  }} className={classNames(!selectedDay ? "hidden" : "text-lime-500 hover:text-lime-400 border-lime-500 hover:border-lime-400", "mb-8 text-sm  border rounded-full py-1 px-3")}>Show All Events</button>

                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {faq.map((item) => (
                    <div key={item.question} onClick={() => {
                      setFAQ(
                        faq.map((faqItem) => {
                          if (item.question === faqItem.question) {
                            return { ...faqItem, open: !faqItem.open }
                          } else {
                            return { ...faqItem }
                          }
                        })
                      );
                    }} className={classNames(showEvent(item) ? "" : "hidden", "text-sm lg:text-base h-fit bg-slate-600/50 p-5 sm:p-6 rounded-2xl sm:rounded-3xl text-left cursor-pointer")}>
                      <div className="flex gap-x-2 w-full justify-between text-slate-100">
                        <span>
                          {item.img ? <div className="max-h-16 w-36 mb-4 flex"><img src={item.img} alt={item.question} className="self-start max-h-12 rounded-md" /></div> : <></>}
                          <span className="font-bold block pb-2">{item.question}</span>{item.date}<br />{item.time}</span>
                        {item.open ? <ChevronUpIcon className="w-6 h-6 inline" /> : <ChevronDownIcon className="w-6 h-6 inline" />}
                      </div>

                      <div className={"pt-4 text-slate-300 " + (item.open ? "block" : "hidden")}>
                        {item.answer}
                        {item.link ? <HashLink to={item.link} target="_blank" rel="nofollow" onClick={(e) => e.stopPropagation()} className="block mt-4 text-lime-500 hover:text-lime-400">Learn more</HashLink> : <></>}
                      </div>

                    </div>
                  ))}
                </div>

              </div>
            </div>

            <Footer />

          </div>
        </div>
      </FacebookProvider> </main>
    </>

  );
}

export default Calendar;
