
import { HashLink } from 'react-router-hash-link';
import { useState, Fragment } from 'react';
import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';


function Header(props) {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigation = [
    { name: 'Home', href: '/'},
    // { name: 'Location', href: '#location' },
    { name: 'Location & Hours', href: '/#hours' },
    { name: 'FAQ', href: "/#faq" },
    { name: 'Safety & Rules', href: '/#safety' },
    // { name: 'Rules', href: '#rules' },
    { name: 'Facebook', href: 'https://www.facebook.com/profile.php?id=61563937867187'},
    // { name: '2024 Calendar', href: '/calendar' },
    { name: 'Contact Us', href: '/#contact' },
    // { name: '2024 Sponsors', href: "#sponsors" },
  ];

  return <>
    <header className={"fixed inset-x-0 top-0 z-50 transition-colors duration-500 " + (props.scrolled ? "bg-slate-800 shadow-2xl" : (props.scrolledLarge ? "lg:bg-slate-800 lg:shadow-2xl" : ""))} >
          <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
            <div className="flex lg:flex-1">
            <span className={"transition-opacity duration-500 " + (props.scrolled ? "opacity-100" : (props.scrolledLarge ? "opacity-0 lg:opacity-100" : "opacity-0"))}>
              <span className="sr-only">Forsaken Souls Haunted Attraction</span>
              <HashLink to="#home" className="flex flex-row items-center gap-x-5"><img
                alt=""
                src="/images/forsakensouls-shirt.png"
                className="h-6 w-auto"
              /><img
              alt=""
              src="/images/spookeasy-transparent.png"
              className="h-6 w-auto"
            /></HashLink>
            </span>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(true)}
                className="-m-2.5 inline-flex items-center justify-center rounded-full p-2.5 text-lime-500 bg-slate-800 shadow-2xl"
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <HashLink key={item.name} to={item.href} className="text-sm font-semibold leading-6 text-slate-50 hover:text-lime-500">
                  {item.name}
                </HashLink>
              ))}
            </div>
            {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <HashLink to="#" className="text-sm font-semibold leading-6 text-slate-50">
                Log in <span aria-hidden="true">&rarr;</span>
              </HashLink>
            </div> */}
          </nav>
          <Transition.Root show={mobileMenuOpen} as={Fragment}>
            <Dialog onClose={setMobileMenuOpen} className="lg:hidden">
              <div className="fixed inset-0 z-50" />
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-slate-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-slate-50/10 opacity-95">
                  
                <div
            aria-hidden="true"
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-2xl sm:-top-80"
          >
            
            <div
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            />
            </div>
                  
                  <div className="flex items-start justify-between">
                    <span className="-ml-1 p-1.5">
                      <span className="sr-only">Forsaken Souls Haunted Attraction</span>
                      <span onClick={() => setMobileMenuOpen(false)} className="flex flex-row items-center gap-x-5"><img
                        alt=""
                        src="/images/forsakensouls-shirt.png"
                        className="h-12 w-auto"
                      /><img
                      alt=""
                      src="/images/spookeasy-transparent.png"
                      className="h-8 w-auto"
                    /></span>
                    </span>
                    <button
                      type="button"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-m-2.5 inline-flex items-center justify-center rounded-full p-2.5 text-lime-500 bg-slate-800 shadow-2xl"
                      >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                    </button>
                  </div>
                  {/* <div className="mt-6 mb-4 text-slate-50 text-lg ghoulish">
                    The Spookeasy Haunted House is <span className="text-lime-500">free</span> this year!
                  </div> */}
                  {/* <hr className="border-lime-500" /> */}
                  <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-slate-500/25">
                      <div className="space-y-2 py-6">
                        {navigation.map((item) => (
                          <HashLink
                            key={item.name}
                            to={item.href}
                            onClick={() => setMobileMenuOpen(false)}
                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-slate-50 hover:bg-slate-800 hover:text-lime-500"
                          >
                            {item.name}
                          </HashLink>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div
            aria-hidden="true"
            className="absolute inset-x-0 top-[calc(100%-30rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-50rem)]"
          >
            <div
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            />
          </div>
                </DialogPanel>
              </Transition.Child>
            </Dialog>
          </Transition.Root>
        </header>
  </>

}

export default Header;
