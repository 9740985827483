import { useState, useEffect, Fragment } from 'react'
import { ArrowDownTrayIcon, CheckCircleIcon, ChevronDoubleDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { HashLink } from 'react-router-hash-link';
import Footer from './components/footer';
import Header from './components/header';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { Dialog, DialogPanel, Transition } from '@headlessui/react';
import {isMobile, MobileView} from 'react-device-detect';

function Gallery() {

  let [scrolled, setScrolled] = useState(false);
  let [scrolledLarge, setScrolledLarge] = useState(false);

  // useEffect(() => {
  //   window.addEventListener('scroll', (event) => {
  //     let scrollTop = event.target.scrollingElement.scrollTop;
  //     let elementPosition = document.getElementById('description').offsetTop;

  //     if (scrollTop > document.getElementById('logos').offsetTop + 100) {
  //       setScrolledLarge(true);
  //     } else {
  //       setScrolledLarge(false);
  //     }

  //     if (scrollTop > elementPosition) {
  //       setScrolled(true);
  //     } else {
  //       setScrolled(false);
  //     }

  //   });
  // }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const download = e => {
    console.log(e.target.href);
    e.preventDefault();
    e.stopPropagation();
    fetch(e.target.href, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image." + e.target.href.split(".")[1]); //or any other extension
          document.body.appendChild(link);

          link.click();
          
        });
      })
      .catch(err => {
        console.log(err);
      }).finally(() => {
        if (e.target.href.indexOf(activeImage.src) !== -1) {
          setActiveImage({
            ...activeImage,
            downloaded: true
          });
        }
        setImages(images.map((i) => {
          if (e.target.href.indexOf(i.src) !== -1) {
            return {
              ...i,
              downloaded: true
            };
          }else{
            return i;
          }
        }
      ));
      });
  };

  const [activeImage, setActiveImage] = useState(null);
  const [imageOpen, setImageOpen] = useState(false);

  function openImage(image) {
    setActiveImage(image);
    setImageOpen(true);
  }

  const [breakpoints, setBreakpoints] = useState({350: 1, 750: 2, 900: 3});

  const [images, setImages] = useState([
    {
      src: "/images/bar.jpg",
      alt: "bar",
      open: false,
      downloaded: false,
    },
    {
      src: "/images/yard.jpg",
      alt: "bar",
      open: false,
      downloaded: false,
    },
    {
      src: "/images/family.jpg",
      alt: "bar",
      open: false,
      downloaded: false,
    },
  ]);

  return (
    <>
      <main id="main">

        <div className="bg-slate-900">

        <Transition.Root show={imageOpen} as={Fragment}>
          <Dialog onClose={setImageOpen}>
            <DialogPanel focus="true" className="fixed inset-0 z-50">
              <div className="absolute inset-0 bg-slate-900 bg-opacity-75"  />
              <div className="absolute right-6 top-6">
                <button
                  type="button"
                  onClick={() => setImageOpen(false)}
                  className="-m-2.5 inline-flex items-center justify-center rounded-full p-2.5 text-lime-500 bg-slate-800 shadow-2xl"
                  >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>
              <div className="absolute inset-0 flex flex-col gap-6 justify-center items-center m-6" onClick={() => setImageOpen(false)}>
                <img src={activeImage?.src} alt={activeImage?.alt} className="max-w-full rounded-2xl shadow-xl max-w-3xl" />
                <a
                  href={activeImage?.src}
                  onClick={ (e) => {if (!activeImage?.downloaded) return download(e)}}
                  download
                  disabled={activeImage?.downloaded}
                  className={classNames(activeImage?.downloaded ? "bg-slate-400 text-slate-50" : "shadow-xl bg-teal-500 text-slate-50  hover:bg-lime-500", "flex gap-x-2 rounded-full  px-3.5 py-2.5 text-lg font-semibold pl-6 pr-6 shadow-sm")}
                >
                  {activeImage?.downloaded ? <>
                    <CheckCircleIcon className="w-5" /> Downloaded
                  </> : <>
                    <ArrowDownTrayIcon className="w-5" /> Download
                  </>}
                </a>
              </div>
            </DialogPanel>
          </Dialog>
        </Transition.Root>

          <Header scrolled={scrolled} scrolledLarge={scrolledLarge} />

          <div id="home" className="relative isolate">

            <div
              aria-hidden="true"
              className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-2xl sm:-top-80"
            >

              <div
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              />

            </div>
            <div className="pt-16 sm:pt-16 lg:pt-32 pb-12 relative overflow-hidden shadow-xl">
              <img
                alt=""
                src="/images/bar.jpg"
                className="absolute inset-0 -z-10 h-full w-full object-cover blur-sm contrast-125 opacity-50 overflow-hidden"
              />
              <div className="mx-auto max-w-2xl">
                <div className="text-center" id="logos">
                  <HashLink to="/"><img src="/images/forsakensouls-transparent.png" alt="Forsaken Souls Haunted Attraction" /></HashLink>

                  <h1 className="text-3xl text-slate-50 sm:text-4xl ghoulish pt-6 sm:pt-0 drop-shadow" id="description">
                    Gallery
                  </h1>

                  <HashLink to="#location" className="animate-bounce block">
                    <ChevronDoubleDownIcon aria-hidden="true" className="h-6 w-6 text-lime-500 mx-auto mt-12" />
                  </HashLink>
                </div>

              </div></div>

              <MobileView>
                <div className="pt-12 px-6 mx-auto max-w-5xl text-center text-slate-50 text-lg">
                  Press and hold to save image.
                </div>
              </MobileView>

              <div className="pt-12 px-6 mx-auto max-w-5xl"><ResponsiveMasonry
                columnsCountBreakPoints={breakpoints}
            >
                <Masonry gutter={"20px"}>

                {images.map((item) => (
                    <div className="relative cursor-pointer group" key={item?.src}>
                      <img src={item?.src} alt={item?.alt} onClick={() => {
                        if (!isMobile) {
                          openImage(item);
                        }
                      }} className="group-hover:brightness-125 rounded-2xl overflow-hidden shadow-xl" />
                      {/* {item.open && (
                        <div className="absolute w-full block bottom-6 flex justify-center items-center ">
                          <a
                            href={item.src}
                            onClick={ (e) => {if (!item.downloaded) return download(e)}}
                            download
                            disabled={item.downloaded}
                            className={classNames(item.downloaded ? "bg-slate-500" : "shadow-xl bg-teal-500 text-slate-50  hover:bg-lime-500", "flex gap-x-2 rounded-full  px-3.5 py-2.5 text-lg font-semibold pl-6 pr-6 shadow-sm")}
                          >
                            {item.downloaded ? <>
                              <CheckCircleIcon className="w-5" /> Downloaded
                            </> : <>
                              <ArrowDownTrayIcon className="w-5" /> Download
                            </>}
                          </a>
                        </div>
                      )} */}
                    </div>
                  ))}

                  
                </Masonry>
                </ResponsiveMasonry></div>

            <Footer />

          </div>
        </div>
      </main>
    </>

  );
}

export default Gallery;
