import { useState, useEffect } from 'react'
import { ChevronDownIcon, ChevronUpIcon, ChevronDoubleDownIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';
import { HashLink } from 'react-router-hash-link';
import Footer from './components/footer';
import Header from './components/header';

function App() {

  const sponsors = [
    {
      name: 'Branded Board Designs',
      imageUrl: '/images/brandedboard.jpg',
      note: 'Rhonda went above and beyond donating over 60 gallons of paint, be sure to check out Branded Board for custom wood engraving!',
      facebook: 'https://www.facebook.com/profile.php?id=100087542497539',
      website: 'https://www.brandedboard.us',
    },
    {
      name: 'Summers Painting',
      imageUrl: '/images/summerspainting.jpg',
      note: 'Summers Painting has graciously contributed to our candy budget for  Trick \'r Treat, be sure to check them out for your painting needs!',
      facebook: 'https://www.facebook.com/profile.php?id=100093312161594',
      website: 'https://www.summerspainting.net',
    },
  ]

  const [faq, setFAQ] = useState([
    //   {
    //     question: "Is this inside your house?",
    //     answer: "No, our haunted house will be located in our backyard, built under a party tent with temporary walls and props.",
    //     open: false
    //   },{
    //     question: "Is this like other Halloween yard displays?",
    //     answer: "No, Forsaken Souls is a fully built \"commercial-style\" haunted house experience with live actors, special effects scares, and more. It just so happens it's a free home haunt in our backyard! While we have kid friendly hours, the atmosphere of the Haunted House is designed to be scary.",
    //     open: false
    //   },{
    //     question: "Do you have parking available?",
    //     answer: "Since Forsaken Souls is a home haunt, we do not have a parking lot. However, there is plenty of street parking available on S 6th Street and nearby streets. You can also use the lot at West Side Park / Larson Acres park on S 6th Street.",
    //     open: false
    //   },{
    //     question: "How big is your haunted house?",
    //     answer: "Our haunted house is a 800 sq ft (20'x40') walk-through experience with eight different themed rooms. While we might have a line to get in, the actual haunted house walkthrough will take less than 5 minutes.",
    //     open: false
    //   },{
    //     question: "Is your haunted house too scary for my child?",
    //     answer: "During Kid Friendly times, the haunted house will have Trick or Treat with all of our actors being friendly and handing out treats, however they will be wearing makeup. All motion activated scares and props will be turned off. The atmosphere is still designed to be scary (a run down speakeasy), but you can walk through with a friendly actor guide if you'd like. We have no gore in the haunted house, but there are a few skeletons. Overall, you know your child best, but we recommend children under 13 come during Kid Friendly hours. Full Scare hours will be much darker, all actors will scare you, and all props will be active. We recommend children under 13 do not come during Full Scare hours unless they have previous experience with a commercial haunted attractions.",
    //     open: false
    // },{
    //     question: "Is your haunted house scary?",
    //     answer: "We have different kid friendly and teen/adult hours to cater to different scare levels. However, we are a haunted house and the atmosphere is designed to be scary. If you get too scared, put your hands on your head and say 'I quit!' and our actors will lead you to the nearest exit.",
    //     open: false
    //   },{
    //     question: "What's different between kid and teen/adult hours?",
    //     answer: "Kid friendly hours are during the day when the haunted house is well lit. The actors will also be friendly and hand out treats, and we will tone down any startle scares to be less intense. Kid friendly does not mean that your child won't get scared, but we will do our best to make it a fun experience for them. Teen & Adult hours will have the full scare experience!",
    //     open: false
    //   },{
    //     question: "Can I go through kid friendly hours as an adult?",
    //     answer: "Absolutely! Anyone is welcome during kid friendly hours, whether you go through with your children or if you would just like a less scary experience for yourself.",
    //     open: false
    //   },{
    //     question: "Is my child old enough to go through?",
    //     answer: "We always recommend that parents go through with their children if they are younger than 13 years old and come during kid friendly hours. We recommend you do not bring children younger than 13 during teen/adult hours unless they have previous experience with commercial haunted attractions.",
    //     open: false
    //   },
    {
      question: "Is your haunted house handicap accessible?",
      answer: "We have built the haunted house to be as accessible as possible, and it should be able to fit a motorized wheelchair through all doors, openings, and props. Actors are available to go behind your group to ensure you don't encounter any issues.",
      open: false
    },
    // {
    //   question: "Do you accept donations?",
    //   answer: "We appreciate the gesture with all of our hearts (if we had them), and would be very thankful if you donated to a local charity to help support the community instead of us. Some of our favorites are Evansville AWARE and The Care Closet!",
    //   open: false
    // },
    // {
    //   question: "Are you open on nights other than Halloween?",
    //   answer: "Yes! We are open on Sunday October 27th as well, please see our hours for details.",
    //   open: false
    // },
    {
      question: "What are your plans for 2025?",
      answer: "In 2025 Forsaken Souls will be opening a commercial haunted house at its own location. We'll post more information as we know more!",
      open: false
    },
    {
      question: "Are you looking for more actors?",
      answer: "We are definitely looking for more actors for our 2025 season! Please contact us if you're interested in scaring with us.",
    }
  ])

  let [scrolled, setScrolled] = useState(false);
  let [scrolledLarge, setScrolledLarge] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', (event) => {
      let scrollTop = event.target.scrollingElement.scrollTop;
      let elementPosition = document.getElementById('description').offsetTop;

      if (scrollTop > document.getElementById('logos').offsetTop + 100) {
        setScrolledLarge(true);
      } else {
        setScrolledLarge(false);
      }

      if (scrollTop > elementPosition) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }

    });
  }, []);



  return (
    <>
      <main id="main">

        <div className="bg-slate-900">

          <Header scrolled={scrolled} scrolledLarge={scrolledLarge} />

          <div id="home" className="relative isolate">

            <div
              aria-hidden="true"
              className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-2xl sm:-top-80"
            >

              <div
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              />

            </div>
            <div className="pt-16 sm:pt-16 lg:pt-32 pb-12 relative overflow-hidden shadow-xl">
              {/* <div className="pt-16 sm:pt-16 lg:pt-32 pb-24 relative overflow-hidden border-b-2 border-lime-500 shadow-2xl"> */}
              <img
                alt=""
                src="/images/bar.jpg"
                className="absolute inset-0 -z-10 h-full w-full object-cover blur-sm contrast-125 opacity-50 overflow-hidden"
              />
              <div className="mx-auto max-w-2xl ">
                <div className="text-center" id="logos">
                  <HashLink to="/"><img src="/images/forsakensouls-transparent.png" alt="Forsaken Souls Haunted Attraction" /></HashLink>

                  <h1 className="text-3xl text-slate-50 sm:text-4xl ghoulish pt-6 sm:pt-0 drop-shadow">
                    Presents the
                  </h1>
                  <center><img src="/images/spookeasy-shadow.png" alt="Spookeasy Haunted House" className="mt-12 max-w-xs sm:max-w-lg" /></center>
                  <p id="description" className="mt-12 text-lg leading-8 text-slate-200 px-6 drop-shadow">
                    Our haunted house will over triple in size for the 2025 season! Stay tuned for more information about our new location.
                  </p>
                  <HashLink to="#location" className="animate-bounce block">
                    <ChevronDoubleDownIcon aria-hidden="true" className="h-6 w-6 text-lime-500 mx-auto mt-12" />
                  </HashLink>
                  {/* <div className="mt-10 flex items-center justify-center gap-x-6">
                <HashLink
                  to="#"
                  className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-slate-50 shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                >
                  Get started
                </HashLink>
                <HashLink to="#" className="text-sm font-semibold leading-6 text-slate-50">
                  Learn more <span aria-hidden="true">→</span>
                </HashLink>
              </div> */}
                  {/* </div> */}
                </div>

              </div></div>
            <div id="location" className="mx-auto max-w-5xl px-6 pt-24">
              <dl className="mx-auto grid max-w-xl grid-cols-1 md:grid-cols-2 md:max-w-2xl gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                {/* {features.map((feature) => (
                    <div key={feature.name} className="flex flex-col">
                      <dt className="text-lg leading-7 text-slate-50 ghoulish">
                        <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-lime-500">
                          <feature.icon aria-hidden="true" className="h-6 w-6 text-slate-50" />
                        </div>
                        {feature.name}
                      </dt>
                      <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-slate-300">
                        <p className="flex-auto">{feature.description}</p>
                        { feature.href !== "" ? <p className="mt-6">
                          <HashLink to={feature.href} target="_blank" rel="noreferrer" className="text-sm font-semibold leading-6 text-indigo-400 hover:text-indigo-300">
                            {feature.hreftext || ""} <span aria-hidden="true">→</span>
                          </HashLink>
                        </p> : null }
                      </dd>
                    </div>
                  ))} */}
                <div className="flex flex-col">
                  <dt className="text-lg leading-7 text-slate-50 ghoulish">
                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-lime-500">
                      {/* <HomeIcon aria-hidden="true" className="h-6 w-6 text-slate-50 brightness-0" /> */}

                      <img src="/images/whiskey.png" alt="Whiskey Glass" aria-hidden="true" className="h-6 w-6 text-slate-50 brightness-0" />
                    </div>
                    The Spookeasy
                  </dt>
                  <dd className="mt-1 flex flex-col text-base leading-7 text-slate-300">
                    <p className="flex-auto">Enter an abandoned speakeasy filled with the deranged victims of a madman obsessed with the occult, if you dare.</p>

                    {/* { feature.href !== "" ? <p className="mt-6">
                        <HashLink to={feature.href} target="_blank" rel="noreferrer" className="text-sm font-semibold leading-6 text-indigo-400 hover:text-indigo-300">
                          {feature.hreftext || ""} <span aria-hidden="true">→</span>
                        </HashLink>
                      </p> : null } */}
                  </dd>
                </div>
                <div className="flex flex-col">
                  <dt className="text-lg leading-7 text-slate-50 ghoulish">
                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-lime-500">
                      <CalendarDaysIcon aria-hidden="true" className="h-6 w-6 text-slate-50 brightness-0" />
                    </div>
                    What's new for 2025?
                  </dt>
                  <dd className="mt-1 flex flex-col text-base leading-7 text-slate-300">
                    <p className="flex-auto">In 2025 Forsaken Souls will open as a commercial haunted house in a new location and will over triple in size!</p>
                    {/* <p className="mt-2">
                        <HashLink to="#hours" className="text-sm font-semibold leading-6 text-indigo-400 hover:text-indigo-300">
                          View our Hours <span aria-hidden="true">→</span>
                        </HashLink>
                      </p> */}
                    {/* { feature.href !== "" ? <p className="mt-6">
                        <HashLink to={feature.href} target="_blank" rel="noreferrer" className="text-sm font-semibold leading-6 text-indigo-400 hover:text-indigo-300">
                          {feature.hreftext || ""} <span aria-hidden="true">→</span>
                        </HashLink>
                      </p> : null } */}
                  </dd>
                </div>
                <div className="flex flex-col">
                  <dt className="text-lg leading-7 text-slate-50 ghoulish">
                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-lime-500">
                      <img src="/images/facebook.png" alt="Facebook" aria-hidden="true" className="h-6 w-6 text-slate-50 brightness-0" />
                    </div>
                    Follow us on Facebook
                  </dt>
                  <dd className="mt-1 flex flex-col text-base leading-7 text-slate-300">
                    <p className="flex-auto">Get the latest updates on our Facebook page, or message us with any questions.</p>
                    <p className="mt-2">

                      <HashLink to="https://www.facebook.com/profile.php?id=61563937867187" target="_blank" rel="noreferrer" className="text-sm font-semibold leading-6 text-indigo-400 hover:text-indigo-300">
                        Visit our Facebook Page <span aria-hidden="true">→</span>
                      </HashLink>
                    </p>
                  </dd>
                </div>
              </dl>
            </div>
            <div id="hours" className="mx-auto max-w-5xl px-6 pt-24">
              <center><h1 className="text-3xl text-lime-500 sm:text-4xl ghoulish pb-8">
                Location & Hours
              </h1><p className="leading-8 text-slate-300 max-w-2xl text-left sm:text-center">
                  Our hours and location for the 2025 season will be posted here when we get closer to September of 2025. Stay tuned!
                </p></center>
              {/* <dl className="grid mx-auto max-w-xl grid-cols-1 md:grid-cols-2 md:max-w-2xl gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                <div className="flex flex-col">
                    <dt className="text-lg leading-7 text-slate-50 ghoulish">
                      <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-lime-500">
                      <CameraIcon aria-hidden="true" className="h-6 w-6 text-slate-50 brightness-0" />
                      </div>
                      Photo Ops & Trick 'r Treat
                    </dt>
                    <dd className="mt-1 flex flex-col text-base leading-7 text-slate-300">
                      <p className="flex-auto pb-1 text-slate-100">5pm-10pm on Halloween</p>
                      <p className="flex-auto">At any time during our Halloween hours, you can stop by for our photo ops and Trick 'r Treat even if you don't want to go through the Haunt.</p>
                    </dd>
                  </div>
                  <div className="flex flex-col">
                    <dt className="text-lg leading-7 text-slate-50 ghoulish">
                      <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-lime-500">
                        <img src="/images/ghost.png" alt="Ghost" aria-hidden="true" className="h-6 w-6 text-slate-50 brightness-0" />
                      </div>
                      Kid Friendly Tour
                    </dt>
                    <dd className="mt-1 flex flex-col text-base leading-7 text-slate-300">
                      <p className="flex-auto pb-1 text-slate-100">2pm-5pm on Sunday Oct. 27th,<br />5pm-7pm on Halloween</p>
                      <p className="flex-auto">Families can go through our Haunted House during the day where you'll get a less scary experience with friendly actors handing out treats!</p>
                    </dd>
                  </div>
                  
                  <div className="flex flex-col">
                    <dt className="text-lg leading-7 text-slate-50 ghoulish">
                      <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-lime-500">
                      <img src="/images/grim-reaper.png" alt="Grim Reaper" aria-hidden="true" className="h-6 w-6 text-slate-50 brightness-0" />
                      </div>
                      Teens & Adults
                    </dt>
                    <dd className="mt-1 flex flex-col text-base leading-7 text-slate-300">
                      <p className="flex-auto pb-1 text-slate-100">6:30pm-9pm on Sunday Oct. 27th,<br />7pm-10pm on Halloween</p>
                      <p className="flex-auto">The full Haunted House experience, come prepared to be scared by our live actors and special effects! We won't hold back.</p>
                    </dd>
                  </div>
                  
                </dl> */}
            </div>
            <div id="testimonial" className="relative">
              <div>
                <div className="mx-auto flex max-w-5xl flex-col items-center gap-x-8 gap-y-10 px-6 sm:gap-y-8 lg:px-8">
                  <div className="mt-24 w-full max-w-2xl pl-0 pr-0 md:pl-8 md:pr-8 lg:pl-0 lg:pr-0 lg:w-1/2 lg:h-[28rem]">
                    <div className="relative aspect-[1/1] h-full md:-mx-8 lg:mx-0 lg:aspect-auto">
                      <img width={384} height={436}
                        className="absolute inset-0 h-full w-full rounded-2xl bg-slate-800 object-cover object-center shadow-2xl border border-slate-500"
                        src="/images/family.jpg"
                        alt="The Kremer Family"
                      />
                      <div className="absolute inset-0 h-full w-full family-glitch rounded-2xl overflow-hidden border border-slate-500 object-cover object-center" />
                    </div>
                  </div>
                  <div className="w-full max-w-2xl lg:max-w-none lg:flex-auto lg:px-16 lg:pt-12">
                    <figure className="relative isolate pt-6 sm:pt-12">
                      <svg
                        viewBox="0 0 162 128"
                        fill="none"
                        aria-hidden="true"
                        className="absolute left-0 top-0 -z-10 h-32 stroke-slate-50/20"
                      >
                        <path
                          id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb"
                          d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z"
                        />
                        <use to="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" x={86} />
                      </svg>
                      <blockquote className="text-xl font-semibold leading-8 text-slate-50 sm:text-2xl sm:leading-9">
                        <p>
                          Matt & Sarah Beth met while acting at a Haunted House in Waukesha, WI and have been scaring together ever since. They started Forsaken Souls Haunted Attraction to share their love of Halloween with everyone ... and scare the pants off them too!
                        </p>
                      </blockquote>
                      <figcaption className="mt-8 text-base">
                        <div className="font-semibold text-slate-400">The Kremer Family</div>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>



            <div id="faq" className="max-w-5xl mx-auto">
              <div className="mx-auto max-w-5xl px-6 pt-24">
                <center><h1 className="text-3xl text-lime-500 sm:text-4xl ghoulish pb-8">
                  FAQ
                </h1>
                </center>


                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {faq.map((item) => (
                    <div key={item.question} onClick={() => {
                      setFAQ(
                        faq.map((faqItem) => {
                          if (item.question === faqItem.question) {
                            return { ...faqItem, open: !faqItem.open }
                          } else {
                            return { ...faqItem }
                          }
                        })
                      );
                    }} className="text-sm lg:text-base h-fit bg-slate-600/50 p-5 sm:p-6 rounded-2xl sm:rounded-3xl text-left cursor-pointer">
                      <div className="flex gap-x-2 w-full justify-between text-slate-100">
                        <span>{item.question}</span>
                        {item.open ? <ChevronUpIcon className="w-6 h-6 inline" /> : <ChevronDownIcon className="w-6 h-6 inline" />}
                      </div>
                      <div className={"pt-4 text-slate-300 " + (item.open ? "block" : "hidden")}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>

              </div>
            </div>

            <div>
              <div className="mx-auto flex max-w-5xl flex-col gap-x-8 px-6 md:flex-row px-6">
                <div id="safety" className="w-full pt-24">
                  <h1 className="text-3xl text-lime-500 sm:text-4xl ghoulish pb-8 text-center">
                    Safety
                  </h1>
                  <div className="flex flex-col gap-y-6">
                    <p className="text-base leading-8 text-slate-300">
                      We have a number of safety measures in place to ensure that everyone has a fun and safe experience at our Haunt.
                    </p>
                    <p className="text-base leading-8 text-slate-400">
                      <b className="text-slate-200">24/7 Surveilance: </b> The outside and inside of the Haunted House are monitored at all times by security cameras.
                    </p>
                    <p className="text-base leading-8 text-slate-400">
                      <b className="text-slate-200">Quitting the Haunt: </b> If at any time you'd like to leave the Haunt, just put your hands on your head and say "I quit!" Our actors will guide you to the nearest exit. You will not be allowed back in the Haunted House once you've quit.
                    </p>
                    <p className="text-base leading-8 text-slate-400">
                      <b className="text-slate-200">Flashing Lights & Fog: </b> Our Haunted House contains flashing lights, strobe effects, and fog. If you are sensitive to these effects, please refrain from entering the Haunted House.
                    </p>
                    <p className="text-base leading-8 text-slate-400">
                      <b className="text-slate-200">Pregnancy & Medical Issues: </b> If you are pregnant, have a heart condition, or any other medical issue that may be affected by a Haunted House, we recommend that you do not enter.
                    </p>
                    <p className="text-base leading-8 text-slate-400">
                      <b className="text-slate-200">Mobility Concerns: </b> Our Haunted House is on uneven ground. If you have mobility concerns, please let us know and we will do our best to accommodate you.
                    </p>
                    <p className="text-base leading-8 text-slate-400">
                      <b className="text-slate-200">Fire Safety: </b> We have fire extinguishers and smoke detectors throughout the Haunted House. Everything in the Haunted House has been treated with flame retardant spray.
                    </p>
                    <p className="text-base leading-8 text-slate-400">
                      <b className="text-slate-200">Emergency Exits: </b> We have multiple emergency exits throughout the Haunted House. Our actors are trained to help guide guests to the nearest exit in case of an emergency.
                    </p>

                  </div>
                </div>
                <div id="rules" className="w-full pt-24">
                  <h1 className="text-3xl text-lime-500 sm:text-4xl ghoulish pb-8 text-center">
                    Rules
                  </h1>
                  <div className="flex flex-col gap-y-6">
                    <p className="text-base leading-8 text-slate-300">
                      In order to ensure the safety of all guests and actors, we have a few rules that we ask everyone to follow.
                    </p>
                    <p className="text-base leading-8 text-slate-400">
                      <b className="text-slate-200">Right of Refusal: </b> We reserve the right to refuse entry or remove anyone who doesn't follow our rules or causes a safety concern for our guests.
                    </p>
                    <p className="text-base leading-8 text-slate-400">
                      <b className="text-slate-200">No Touching: </b> Our actors will never touch you, you should not touch them. Do not touch any props or decorations in the Haunted House.
                    </p>
                    <p className="text-base leading-8 text-slate-400">
                      <b className="text-slate-200">No Fire Hazards or Smoking: </b> Do not bring any open flames into the Haunted House. This includes candles, lighters, and matches. There is also No Smoking allowed on the property.
                    </p>
                    <p className="text-base leading-8 text-slate-400">
                      <b className="text-slate-200">No Running: </b> Walk through the Haunted House at a normal pace. Running can be dangerous for both you and our actors.
                    </p>
                    <p className="text-base leading-8 text-slate-400">
                      <b className="text-slate-200">No Weapons: </b> Do not bring any weapons into the Haunted House. This includes knives, guns, and any other type of weapon.
                    </p>
                    <p className="text-base leading-8 text-slate-400">
                      <b className="text-slate-200">No Alcohol or Drugs: </b> Do not bring any alcohol or drugs into the Haunted House. Anyone who appears to be under the influence will be asked to leave.
                    </p>
                    <p className="text-base leading-8 text-slate-400">
                      <b className="text-slate-200">No Photography Inside: </b> We do not allow photography or videography inside the Haunted House. We will have outdoor photo ops available for you to take pictures!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div id="contact" className="">
              <div className="mx-auto flex max-w-5xl flex-col gap-x-8 gap-y-20 px-6 sm:gap-y-8 md:flex-row  px-6 pt-24 sm:pt-12">
                <div className="w-full">
                  <h1 className="text-3xl text-lime-500 sm:text-4xl ghoulish pb-8 text-center">
                    Contact Us
                  </h1>
                  <div className="flex flex-col gap-y-6 items-center">
                    <p className="text-base leading-8 text-slate-300">
                      Reach out to us with any questions or concerns you may have. We're here to help!
                    </p>
                    <p className="text-base leading-8 text-slate-300 text-center flex flex-col gap-y-6 max-w-sm">
                      <HashLink
                        to="https://www.facebook.com/profile.php?id=61563937867187"
                        target="_blank"
                        rel="noreferrer"
                        className="rounded-full bg-teal-500 px-3.5 py-2.5 text-lg text-slate-50 font-semibold pl-6 pr-6 shadow-sm hover:bg-lime-500"
                      >
                        Message us on Facebook
                      </HashLink>
                      <HashLink
                        to="mailto:info@forsakensouls.com"
                        className="rounded-full bg-teal-500 px-3.5 py-2.5 text-lg text-slate-50 font-semibold pl-6 pr-6 shadow-sm hover:bg-lime-500"
                      >
                        Email Us
                      </HashLink>
                    </p>
                  </div>
                </div>

              </div>

            </div>

            <div id="sponsors" className="max-w-5xl mx-auto">
              <div className="mx-auto max-w-5xl px-6 pt-24">
                <center><h1 className="text-3xl text-lime-500 sm:text-4xl ghoulish pb-8">
                  2024 Sponsors
                </h1>
                  <p className="leading-8 text-slate-300 pb-12 max-w-2xl text-left sm:text-center">
                    We'd like to thank the following sponsors for going above and beyond in supporting Forsaken Souls Haunted Attraction.
                  </p></center>
                <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16">
                  {sponsors.map((sponsor) => (
                    <li key={sponsor.name}>
                      <div className="flex gap-x-6">
                        <img alt="" src={sponsor.imageUrl} className="h-20 w-20 rounded-lg shadow-xl" />
                        <div>
                          <h3 className="text-lg leading-7 text-slate-50 ghoulish">{sponsor.name}</h3>
                          <p className="text-base leading-8 text-slate-300 text-sm">{sponsor.note}</p>
                          <p className="pt-2 text-base leading-8 text-slate-300 text-sm flex gap-x-4 text-sm font-semibold items-center">
                            <HashLink to={sponsor.facebook} target="_blank" rel="noreferrer" className="bg-blend-overlay bg-[#3b5998] hover:brightness-125 p-1 rounded-lg">
                              <img src="/images/facebook.png" alt="Facebook" aria-hidden="true" className="h-5 w-5 text-slate-50" />
                            </HashLink>
                            <HashLink to={sponsor.website} target="_blank" rel="noreferrer" className="text-indigo-400 hover:text-indigo-300">{sponsor.website.replace('https://www.', '')}</HashLink>
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <Footer />

          </div>
        </div>
      </main>
    </>

  );
}

export default App;
